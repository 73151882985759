import EditNoteIcon from '@mui/icons-material/EditNote';
import { useNavigate } from 'react-router-dom';
import { EHSChecklist, FrequencyEnum } from 'types';



import Section, { SectionDataProps } from 'components/Section';



import { getEnumKeyFromValue } from 'utils/transformFn';


const EhsChecklistDetailsSection: React.FC<{
  ehsChecklist: EHSChecklist;
}> = ({ ehsChecklist }) => {
  const navigate = useNavigate();

  const sectionData: SectionDataProps[] = [
    {
      label: 'Refernce ID',
      value: ehsChecklist.referenceId,
      type: 'STRING',
    },
    {
      label: 'Project',
      value: ehsChecklist.project.name,
      type: 'STRING',
    },
    {
      label: 'Doc Name',
      value: ehsChecklist.docName,
      type: 'STRING',
    },
    {
      label: 'Folder',
      value: ehsChecklist.docType.name,
      type: 'LINK',
      navigateTo: `/documents/PROJECT/${ehsChecklist.project._id}/${ehsChecklist.docType.parentFolder?._id}/${ehsChecklist.docType._id}`,
    },
    {
      label: 'Frequency',
      value: getEnumKeyFromValue(FrequencyEnum, ehsChecklist.frequency),
      type: 'STRING',
    },
    {
      label: 'Schedule Starts',
      value: ehsChecklist.scheduleStarts,
      type: 'DATE',
    },
  ];

  if (!!ehsChecklist.scheduleStarts) {
    sectionData.push({
      label: 'Schedule Ends',
      value: ehsChecklist.scheduleEnds,
      type: 'DATE',
    });
  }

  sectionData.push(
    {
      label: 'Assign To',
      value: ehsChecklist.assignTo.map(usr => usr.firstName).join(', '),
      type: 'STRING',
    },
    {
      label: 'Status',
      value: ehsChecklist.status,
      type: 'STRING',
    }
  );

  return (
    <Section
      title="EHS Details"
      collapsible
      data={sectionData}
      action={<EditNoteIcon onClick={() => navigate('edit')} sx={{ cursor: 'pointer' }} />}
    />
  );
};

export default EhsChecklistDetailsSection;