import EditNoteIcon from '@mui/icons-material/EditNote';
import { useState } from 'react';

import { SimplePopup } from 'components/Popup';
import Section, { SectionDataProps } from 'components/Section';

import UpdateProjectForm from './forms/UpdateProjectForm';

const ProjectDetailsSection: React.FC<{
  projectDetails: any;
}> = ({ projectDetails }) => {
  const [enableEditForm, toggleEditForm] = useState(false);
  const sectionData: SectionDataProps[] = [
    {
      label: 'Refernce ID',
      value: projectDetails.referenceId,
      type: 'STRING',
    },
    {
      label: 'Project',
      value: projectDetails.name,
      type: 'STRING',
    },
    {
      label: 'Start Date',
      value: projectDetails.startDate,
      type: 'DATE',
    },
    {
      label: 'End Date',
      value: projectDetails.endDate,
      type: 'DATE',
    },
    {
      label: 'Status',
      value: projectDetails.status,
      type: 'STRING',
    },
  ];

  return (
    <>
      <Section
        title="Project Details"
        collapsible
        data={sectionData}
        action={
          <EditNoteIcon
            sx={{ cursor: 'pointer' }}
            fontSize="small"
            onClick={() => toggleEditForm(true)}
          />
        }
      />

      <SimplePopup
        onClose={() => toggleEditForm(false)}
        open={enableEditForm}
        title="Update Project"
        fullWidth
      >
        <UpdateProjectForm
          cb={() => {
            toggleEditForm(false);
          }}
          project={projectDetails}
        />
      </SimplePopup>
    </>
  );
};

export default ProjectDetailsSection;
