import { useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RestoreIcon from '@mui/icons-material/Restore';
import WarningIcon from '@mui/icons-material/Warning';
import { Grid, Typography } from '@mui/material';
import { useState } from 'react';

// Define your GraphQL mutation
import LoadingIndicator from 'components/LoadingIndicator';
import { ConfirmationPopup, SimplePopup } from 'components/Popup';
import Section from 'components/Section';

import CustomerDetailsCard from './Card';
import { CustomerForm } from './CustomerForm';
import { UPDATE_COMPLIANCE_CUSTOMER, UpdateCustomerForm } from './UpdateCustomerForm';

const ComplianceCustomers: React.FC<{
  customerContacts: any;
  complianceMilestoneId: string;
  loading: boolean;
  refetch?: () => void;
}> = ({ customerContacts, loading, complianceMilestoneId, refetch }) => {
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleUpdateForm, setToggleUpdateForm] = useState(false);
  const [toggleDeleteForm, setToggleDeleteForm] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<any | null>(null);
  const [toggleRevertForm, setToggleRevertForm] = useState(false);
  const [formTitle, setFormTitle] = useState({
    title: '',
    btnText: '',
  });

  const [updateCustomerStatus, { loading: updatingStatus }] = useMutation(
    UPDATE_COMPLIANCE_CUSTOMER
  );

  return (
    <>
      <Section
        endAdornment={
          (customerContacts?.length === 0 || !customerContacts) && (
            <>
              <WarningIcon
                fontSize="small"
                sx={{
                  color: 'yellow',
                }}
              />
              <Typography fontWeight={800}>No Information found</Typography>
            </>
          )
        }
        title="Customers Details"
        collapsible
        defaultCollapsed
        action={
          <PersonAddIcon
            onClick={() => {
              setToggleForm(true);
              setFormTitle({
                title: 'Add Customer',
                btnText: 'Add',
              });
            }}
            fontSize="small"
            sx={{ cursor: 'pointer' }}
          />
        }
      >
        {loading ? (
          <Grid>
            <LoadingIndicator size="1.6rem" />
          </Grid>
        ) : (
          <Grid
            container
            columns={2}
            rowSpacing={2}
            columnSpacing={3}
            alignItems="center"
            pb={2}
            pr={1}
            overflow="auto"
            mt={-1}
          >
            {customerContacts?.map(pp => (
              <Grid item key={pp.referenceId} xs={2} md={1} lg={1}>
                <CustomerDetailsCard
                  people={pp}
                  // formTitle={formTitle}
                  // toggleUpdateForm={toggleUpdateForm}
                  // setToggleUpdateForm={setToggleUpdateForm}
                  action={
                    <>
                      {pp?.status === 'ACTIVE' || pp?.status === null ? (
                        <>
                          <DeleteIcon
                            fontSize="small"
                            onClick={() => {
                              setSelectedPerson(pp);
                              setToggleDeleteForm(true);
                            }}
                            sx={{ cursor: 'pointer' }}
                          />
                          <EditNoteIcon
                            fontSize="small"
                            onClick={() => {
                              setToggleUpdateForm(true);
                              setSelectedPerson(pp);
                              setFormTitle({ title: 'Update Customer', btnText: 'Update' });
                            }}
                            sx={{ cursor: 'pointer' }}
                          />
                        </>
                      ) : (
                        <RestoreIcon
                          fontSize="small"
                          onClick={() => {
                            setToggleRevertForm(true);
                            setSelectedPerson(pp);
                          }}
                          sx={{ cursor: 'pointer' }}
                        />
                      )}
                    </>
                  }
                />
              </Grid>
            ))}
            {(customerContacts?.length === 0 || !customerContacts) && (
              <Grid item my="auto" mt={2} container justifyContent="center">
                <Typography variant="subtitle2">No Contacts has been added yet.</Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Section>
      <SimplePopup
        onClose={() => {
          setToggleForm(false);
        }}
        open={toggleForm}
        title={formTitle.title}
        fullWidth
      >
        <CustomerForm
          formTitle={formTitle}
          refetch={refetch}
          onClose={() => setToggleForm(false)}
        />
      </SimplePopup>
      <SimplePopup
        onClose={() => {
          setToggleUpdateForm(false);
          setSelectedPerson(null);
        }}
        open={toggleUpdateForm}
        title={formTitle.title}
        fullWidth
      >
        <UpdateCustomerForm
          people={selectedPerson || undefined}
          complianceMilestoneId={complianceMilestoneId}
          refetch={refetch}
          onClose={() => setToggleUpdateForm(false)}
        />
      </SimplePopup>
      <ConfirmationPopup
        onConfirmation={() => {
          updateCustomerStatus({
            variables: {
              input: {
                complianceMilestoneId,
                customer: {
                  _id: selectedPerson?._id,
                  status: 'INACTIVE',
                },
              },
            },
            onCompleted: _ => {
              setToggleDeleteForm(false);
              setSelectedPerson(null);
              refetch?.();
            },
          });
        }}
        onClose={() => {
          setToggleDeleteForm(false);
          setSelectedPerson(null);
        }}
        open={toggleDeleteForm}
        fullWidth
        title="Do you want to delete this customer?"
        confirmationLabel="Confirm"
        loading={updatingStatus}
      />
      <ConfirmationPopup
        onConfirmation={() => {
          updateCustomerStatus({
            variables: {
              input: {
                complianceMilestoneId,
                customer: {
                  _id: selectedPerson?._id,
                  status: 'ACTIVE',
                },
              },
            },
            onCompleted: _ => {
              setToggleRevertForm(false);
              setSelectedPerson(null);
              refetch?.();
            },
          });
        }}
        onClose={() => {
          setToggleRevertForm(false);
          setSelectedPerson(null);
        }}
        open={toggleRevertForm}
        fullWidth
        title="Do you want to revert your changes ?"
        confirmationLabel="Confirm"
        loading={updatingStatus}
      />
    </>
  );
};

export default ComplianceCustomers;
