import { COMPLIANCE_DOC_NAMES } from 'types';

import Section, { SectionDataProps } from 'components/Section';

import { getEnumKeyFromValue } from 'utils/transformFn';

const MonthlySubcontractorComplianceDetailsSection: React.FC<{
  monthlyComplianceDetails: any;
}> = ({ monthlyComplianceDetails }) => {
  const sectionData: SectionDataProps[] = [
    {
      label: 'Refernce ID',
      value: monthlyComplianceDetails.referenceId,
      type: 'STRING',
    },
    {
      label: 'Doc Name',
      value: getEnumKeyFromValue(COMPLIANCE_DOC_NAMES, monthlyComplianceDetails.docName),
      type: 'STRING',
    },
    {
      label: 'Doc Description',
      value: monthlyComplianceDetails.docDescription,
      type: 'STRING',
    },
    {
      label: 'Doc Type',
      value: monthlyComplianceDetails.docType,
      type: 'STRING',
    },
    {
      label: 'Folder',
      value: monthlyComplianceDetails?.finalDocScan?.folder
        ? monthlyComplianceDetails?.finalDocScan?.folder?.name
        : 'N/A',
      type: monthlyComplianceDetails?.finalDocScan !== null ? 'LINK' : 'STRING',
      navigateTo:
        monthlyComplianceDetails?.finalDocScan !== null
          ? `/documents/PROJECT/${monthlyComplianceDetails?.project?._id}/${monthlyComplianceDetails?.finalDocScan?.folder?.parentFolder?._id}/${monthlyComplianceDetails?.finalDocScan?.folder?._id}`
          : '',
    },
    {
      label: 'Start Date',
      value: monthlyComplianceDetails.startDate,
      type: 'DATE',
    },
    {
      label: 'End Date',
      value: monthlyComplianceDetails.endDate,
      type: 'DATE',
    },
    {
      label: 'Target Date',
      value: monthlyComplianceDetails.targetDate,
      type: 'DATE',
    },
    {
      label: 'Status',
      value: monthlyComplianceDetails.currentStatus,
      type: 'STRING',
    },
  ];

  return <Section title={`Compliance Details`} collapsible data={sectionData} />;
};

export default MonthlySubcontractorComplianceDetailsSection;
