import { useQuery } from '@apollo/client';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Grid, Typography } from '@mui/material';
import { useFormContext } from 'contexts/useDateRange';
import { GET_WOODKRAFT_MONTHLY_COMPLIANCE_DETAILS_QUERY } from 'graphql/query/compliance';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { SimplePopup } from 'components/Popup';
import Section from 'components/Section';
import { DateSelectorPopup } from 'components/_Compliances/DateSelectorPopup';

import { dateFormatterForMonthlyFilters } from 'utils/transformFn';

import ComplianceChecklistTable from './ComplianceChecklistTable';

const WoodkraftTableSection: React.FC<{}> = ({}) => {
  const [togglePopup, setTogglePopup] = useState(false);

  const { projectId = '' } = useParams<{ projectId: string }>();
  const { formState, setFormState } = useFormContext();

  const [searchParams] = useSearchParams();
  const workStartDate = searchParams.get('workStartDate') || '';
  const workEndDate = searchParams.get('workEndDate') || '';

  const dates = dateFormatterForMonthlyFilters(formState);
  const { startDate, endDate }: any = dates;
  const disableBtn = Object.values(dates).some(date => date === null);

  const skipQuery = startDate === null || endDate === null;

  const { data, loading, refetch } = useQuery(GET_WOODKRAFT_MONTHLY_COMPLIANCE_DETAILS_QUERY, {
    variables: {
      project: projectId,
      monthYearRange: !skipQuery ? [startDate, endDate] : [],
    },
    skip: skipQuery,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!skipQuery) {
      refetch();
    }
  }, [formState.startDate, formState.endDate, refetch, projectId, startDate, endDate]);

  const handleFormSubmit = (tempFormState: any) => {
    setFormState(prevState => ({
      ...prevState,
      startDate: tempFormState.startDate,
      endDate: tempFormState.endDate,
    }));
    setTogglePopup(false);
  };

  const resetFormState = () => {
    // setFormState({ startDate: null, endDate: null });
    setFormState({ startDate: formState.startDate, endDate: formState.startDate });
  };

  return (
    <>
      <Section title="Monthly Compliance Checklist" collapsible>
        {formState.startDate && formState.endDate ? (
          <Grid container justifyContent="space-between" rowGap={1}>
            <Typography variant="h6">
              For a duration of {dates.startDate} - {dates.endDate}
            </Typography>
            <Grid item>
              <RefreshIcon onClick={resetFormState} sx={{ cursor: 'pointer', marginRight: 2 }} />
              <CalendarMonthIcon onClick={() => setTogglePopup(true)} sx={{ cursor: 'pointer' }} />
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="space-between" rowGap={1}>
            <Typography variant="h6">Tasks for the current month</Typography>
            <CalendarMonthIcon onClick={() => setTogglePopup(true)} sx={{ cursor: 'pointer' }} />
          </Grid>
        )}
        <Grid item>
          <ComplianceChecklistTable
            complianceDetails={data?.getMonthlyComplianceDocs}
            loading={loading}
          />
        </Grid>
      </Section>

      <SimplePopup
        onClose={() => setTogglePopup(false)}
        open={togglePopup}
        title="Past submissions"
        fullWidth
      >
        <DateSelectorPopup
          startDate={workStartDate}
          endDate={workEndDate}
          disableBtn={disableBtn}
          handleFormSubmit={handleFormSubmit}
          formState={formState}
        />
      </SimplePopup>
    </>
  );
};

export default WoodkraftTableSection;
